import { Route, Routes, Navigate } from "react-router-dom";
import LoginAdmin from "./doctor/Profile/LoginAdmin";
import DoctorProfile from "./doctor/Profile/DoctorProfile";
import EditDoctorProfile from "./doctor/Profile/EditDoctorProfile";
import PatientMedicalReport from "./doctor/Report/PatientMedicalReport";
import User from "./user";
import ViewMedicalReport from './doctor/Report/ViewMedicalReport';
import Logout from "./doctor/Profile/LogoutForm";
import Subscription from "./Subscription/Subscription"
import LoginHelper from "./doctor/Profile/LoginHelper";
import SubscriptionCard from "./Subscription/SubscriptionCard"
import Helper from "./doctor/helper/Helper";
import EditHelper from './doctor/helper/EditHelper';
import DoctorList from "./doctor/Dashboard-card/doctorList";
import AllPatients from "./doctor/Dashboard-card/AllPatients";
import PatientProfile from "./patient/PatientProfile"
import LoginPatient from "./patient/LoginPatient";
import GetLoginPatientProfile from "./patient/getLoginPatientProfile";
import CreatePatientProfile from "./patient/createPatientProfile";
import { AppointmentBookingSection } from "./patient/appointmentBookingSection";
import PatientHistory from "./patient/patientHistory";
import DoctorBookingWithPatientLogin from "./patient/DoctorBookingWithPatientLogin";
import AddNewDoctor from "./doctor/Dashboard-card/addNewDoctor";
import PatientAppointment from "./doctor/Dashboard-card/PatientAppointment";
import SlotConfirmation from "./patient/SlotConfirmation";
import SubscriptionNewDr from "./Subscription/SubscriptionNewDr";
import SubscriptionConfirmation from "./Subscription/SubscriptionConfirmation";
import { setloggedIn } from "./recoil/atom/setloggedIn";
import { useRecoilState } from "recoil";
import Dependent from "./doctor/Dashboard-card/Dependent";
import Clinic from "./Clinic/Clinic";
import Calender from "./doctor/Dashboard-card/Calender";
import AddLabWork from "./doctor/Dashboard-card/labwork/AddLabWork";
import PaymentList from "./doctor/Dashboard-card/labwork/PaymentList";
import Summary from './doctor/Report/partial/Summary'
import ClinicRegistration from "./doctor/Dashboard-card/ClinicRegistration";
import ClinicSubscription from "./Subscription/ClinicSubscription";
function MainContainer() {
  const [loggedIn] = useRecoilState(setloggedIn);

  return (
    <Routes>
      <Route exact path="/" element={<LoginAdmin />} />
      <Route exact path="/"  >
        <Route index element={loggedIn.length > 0 ? <Navigate to="/doctors" /> : <LoginAdmin />} />
      </Route>
      <Route path="/doctors" >
        <Route index element={loggedIn.length > 0 ? <DoctorList /> : <Navigate to="/" />} />
        <Route path="profile/:doctorId">
          <Route index element={loggedIn.length > 0 ? <DoctorProfile /> : <Navigate to="/" />} />
          <Route path="edit" element={loggedIn.length > 0 ? <EditDoctorProfile /> : <Navigate to="/" />} />
          <Route path="calendar" element={loggedIn.length > 0 ? <Calender /> : <Navigate to="/" />} />
          <Route path="addlab/:doctorId" element={loggedIn.length > 0 ? <AddLabWork /> : <Navigate to='/' />} />
          <Route path="paymentlist/:doctorId" element={loggedIn.length > 0 ? <PaymentList /> : <Navigate to='/' />} />
        </Route>
        <Route path="subscription/:doctorId" >
          <Route index element={loggedIn.length > 0 ? <SubscriptionCard /> : <Navigate to="/" />} />
          <Route path="confirmation" element={loggedIn.length > 0 ? <SubscriptionConfirmation /> : <Navigate to="/" />} />
        </Route>
        <Route path="appointment/:doctorId" >
          <Route index element={loggedIn.length > 0 ? <PatientAppointment /> : <Navigate to="/" />} />
          <Route path="consultation/:reportId" >
            <Route index element={loggedIn.length > 0 ? <PatientMedicalReport /> : <Navigate to="/" />} />
            <Route path="summary" element={loggedIn.length > 0 ? <Summary /> : <Navigate to="/" />} />
          </Route>
          <Route path="report/:reportId" element={loggedIn.length > 0 ? <ViewMedicalReport /> : <Navigate to="/" />} />
        </Route>
        <Route path="patient/:doctorId" >
          <Route index element={loggedIn.length > 0 ? <LoginPatient /> : <Navigate to="/" />} />
          <Route path="patientprofile/:patientId" >
            <Route index element={loggedIn.length > 0 ? <GetLoginPatientProfile /> : <Navigate to="/" />} />
            <Route path="booking" >
              <Route index element={loggedIn.length > 0 ? <AppointmentBookingSection /> : <Navigate to="/" />} />
              <Route path="confirm/:patientAppointmentId" element={loggedIn.length > 0 ? <SlotConfirmation /> : <Navigate to="/" />} />
            </Route>
          </Route>
          <Route path="createprofile/:patientId" element={loggedIn.length > 0 ? <CreatePatientProfile /> : <Navigate to="/" />} />
        </Route>
        <Route path="addnewdoctor" element={loggedIn.length > 0 ? <AddNewDoctor /> : <Navigate to="/" />} />
        <Route path="helper/:doctorId" element={loggedIn ? <Helper /> : <Navigate to="/" />} />
      </Route>

      <Route path="/allpatient" >
        <Route index element={loggedIn.length > 0 ? <AllPatients /> : <Navigate to="/" />} />
        <Route path="patientinfo/:patientId" element={loggedIn ? <PatientProfile /> : <Navigate to="/" />} />
        <Route path="patientappointment/:patientId" element={loggedIn ? <PatientHistory /> : <Navigate to="/" />} />
        <Route path="dependentdata/:patientId" element={loggedIn ? <Dependent /> : <Navigate to="/" />} />
      </Route>

      <Route path="/clinicregistration" element={loggedIn ? <ClinicRegistration /> : <Navigate to="/" />} />

      <Route path="/clinic" element={loggedIn ? <Clinic /> : <Navigate to="/" />} />

      <Route path="/doctorbookingwithpatientlogin/:doctorId" element={loggedIn ? <DoctorBookingWithPatientLogin /> : <Navigate to="/" />} />

      {/* <Route path="/subscription" element={loggedIn ? <Subscription /> : <Navigate to="/" />} /> */}
      <Route>
        <Route path="/subscription/doctorsubscription" element={<Subscription />} />
        <Route path="/subscription/clinicsubscription" element={<ClinicSubscription />} />
      </Route>
      <Route path="/user" element={loggedIn ? <User /> : <Navigate to="/" />} />

      <Route path="/logout" element={< Logout />} />


      <Route path="/edithelper/:helperId" element={loggedIn ? <EditHelper /> : <Navigate to="/" />} />

      <Route path="/loginhelper" element={loggedIn ? <LoginHelper /> : <Navigate to="/" />} />

      <Route path="/subscriptions/:doctorId" element={loggedIn ? <SubscriptionNewDr /> : <Navigate to="/" />} />

      {/* <Route path="/Clinics" /element={loggedIn ? <Clinic /> : <Navigate to="/" />} /> */}

    </Routes>
  )
}
export default MainContainer;