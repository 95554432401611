import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MainInput } from '../../../mainComponent/mainInput';
import LabWorkApi from '../../../services/LabWorkApi';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import toothnumbers from '../../../data/Tooth.json'
import { Box } from '@mui/material';
import AuthApi from '../../../services/AuthApi';
import AppointmentApi from '../../../services/AppointmentApi';

export default function AddLabWorkTable(props) {
    const { doctorId } = props
    const [saveWork, setSaveWork] = useState("");
    const [saveLab, setSaveLab] = useState("");
    const [savepatient, setSavePatient] = useState("");
    const [saveClinic, setSaveClinic] = useState("");
    const [saveTooth, setSaveTooth] = useState([]);
    const [WokType, setWorkType] = useState([]);
    const [patient, setPatient] = useState([]);
    const [lab, setLab] = useState([]);
    const [clinic, setClinic] = useState([]);
    const [labWokData, setLabWorkData] = useState([]);
    const [givenDate, setGivenDate] = useState();
    const [rcvDate, setRcvDate] = useState();
    const [files, setFiles] = useState([]);
    const { getWorkTypes, getlabsInfo, addLabWork } = LabWorkApi()
    const { getDrInfo } = AuthApi()
    const { getPatientListDetails } = AppointmentApi()

    useEffect(() => {
        getLabWorkData();
    }, [])


    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    // const handleUpload = () => {
    //     files.forEach(file => {
    //         const storageRef = storage.ref(`images/${file.name}`);
    //         storageRef.put(file).then(() => {
    //             console.log(`${file.name} uploaded successfully.`);
    //         }).catch(error => {
    //             console.error('Error uploading file: ', error);
    //         });
    //     });
    // };

    const getLabWorkData = () => {
        getWorkTypes()
            .then((result) => {
                setWorkType(result)
            })

        getlabsInfo()
            .then((result) => {
                setLab(result)
            })

        getDrInfo({ doctorId })
            .then((result) => {
                if (result.result[0]["clinicList"]) {
                    const clinics = result.result[0]["clinicList"]
                    setClinic(clinics)
                } else {
                    setClinic('')
                }
            })

        getPatientListDetails({ doctorId })
            .then((result) => {
                const res = result.test.filter(function (item, index) {
                    return index === result.test.findIndex((obj) => {
                        if (item.patientId === obj.patientId)
                            return item
                    })
                })
                const patientNames = res.map(item => item.patientDetails[0].name);
                const list = []
                setPatient(patientNames)
            })
    };

    const addGivenDatePicker = (date) => {
        setGivenDate(date)
    }

    const addRcvDatePicker = (date) => {
        setRcvDate(date)
    }
    const handleWorkType = (event, selectedValue) => {
        event.preventDefault()
        setSaveWork(selectedValue)
    }

    const handleLab = (event, selectedValue) => {
        event.preventDefault()
        setSaveLab(selectedValue)
    }

    const handlePatients = (event, selectedValue) => {
        event.preventDefault()
        setSavePatient(selectedValue)
    }

    const handleClinics = (event, selectedValue) => {
        event.preventDefault()
        setSaveClinic(selectedValue)
    }

    const handleTooths = (event, selectedValue) => {
        event.preventDefault()
        setSaveTooth(selectedValue)
    }

    //for all input onchange method
    const handleInputChange = event => {
        const { name, value } = event.target;
        setLabWorkData({ ...labWokData, [name]: value });
    };

    const save = (e) => {
        e.preventDefault();
        const bodyData = {
            "doctorId": doctorId,
            "workname": labWokData.name,
            "worktype": saveWork.worktype,
            "price": saveWork.price,
            "labname": saveLab.labname,
            "labcontact": saveLab.contact,
            "toothnumber": saveTooth,
            "patient": savepatient,
            "clinic": saveClinic.clinicName,
            "givendate": givenDate,
            "recievedate": rcvDate,
            "comments": labWokData.comment,
            // "documents"       : documents,
        }
        addLabWork(bodyData)
        props.onClick()
        // toast.success("Saved Successfully!")
        // const formData = new FormData();
        // for (let i = 0; i < files.length; i++) {
        //     formData.append('documents', files[i]);
        // }
        // formData.append('doctorId', doctorId);
        // formData.append('workname', labWokData.name);
        // formData.append('worktype', saveWork.worktype);
        // formData.append('price', saveWork.price);
        // formData.append('labname', saveLab.labname);
        // formData.append('labcontact', saveLab.contact);
        // formData.append('toothnumber', labWokData.teeth);
        // // formData.append('patient', patient);
        // formData.append('clinic', saveClinic.clinicName);
        // formData.append('givendate', givenDate);
        // formData.append('recievedate', rcvDate);
        // formData.append('comments', labWokData.comment);
    }

    return (
        <form onSubmit={save}>
            <div className='width84'>
                <div className="row">
                    <div className="col-md-6 ">
                        <div>
                            <label><b>Work Name</b></label>
                            <MainInput
                                type="text"
                                value={labWokData.name}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder='Name'
                                name="name"
                            />
                        </div>

                        <div>
                            <label><b>Work type</b></label>
                            <Autocomplete
                                style={{ width: 200 }}
                                // id={WokType._id}
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                value={saveWork.worktype}
                                onChange={handleWorkType}
                                getOptionLabel={(data) => `${data.worktype}`}
                                options={WokType}
                                renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.worktype}</Box>)}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Work type"
                                    />}
                            />
                        </div>

                        <div className='mt-2'>
                            <label><b>Price</b></label>
                            <MainInput
                                type="text"
                                value={saveWork.price}
                                className="form-control"
                                placeholder='Price'
                                name="price"
                            />
                        </div>

                        <div>
                            <label><b>Patient List</b></label>
                            <Autocomplete
                                style={{ width: 200 }}
                                // id={patient._id}
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                value={savepatient}
                                onChange={handlePatients}
                                getOptionLabel={(data) => `${data}`}
                                options={patient}
                                renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                                noOptionsText={"patients not available"}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Patient Name"
                                    />
                                }
                            />
                        </div>

                        <div className='mt-2'>
                            <label><b>Given Date</b></label>
                            <div className="form-group">
                                <DatePicker
                                    className="datepicker"
                                    onChange={addGivenDatePicker}
                                    value={givenDate}
                                    clearIcon={null}
                                />
                            </div>
                        </div>

                        <div>
                            <label><b>Comment</b></label>
                            <textarea
                                style={{ width: 200 }}
                                type="text"
                                value={labWokData.comment}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder='Optional'
                                name="comment" />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div>
                            <label><b>Tooth Number</b></label>
                            <Autocomplete
                                style={{ width: 200 }}
                                // id={patient._id}
                                multiple={true}
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                value={saveTooth}
                                onChange={handleTooths}
                                getOptionLabel={(data) => `${data.number}`}
                                options={toothnumbers}
                                renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.number}</Box>)}
                                noOptionsText={"patients not available"}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Select Tooth"
                                    />
                                }
                            />
                        </div>

                        <div className='mt-2'>
                            <label><b>Lab Name </b></label>
                            <Autocomplete
                                style={{ width: 200 }}
                                id={lab._id}
                                autoHighlight
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                value={saveLab.labname}
                                onChange={handleLab}
                                getOptionLabel={(data) => `${data.labname}`}
                                options={lab}
                                noOptionsText={"Lab not available"}
                                renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.labname}</Box>)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Lab Name"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />}
                            />
                        </div>

                        <div className='mt-2'>
                            <label><b>Contact</b></label>
                            <MainInput
                                type="text"
                                value={saveLab.contact}
                                className="form-control"
                                placeholder='Contact'
                                name="contact"
                            />
                        </div>

                        <div>
                            <label><b>Clinic List</b></label>
                            <Autocomplete
                                style={{ width: 200 }}
                                // id={clinic._id}
                                disablePortal={true}
                                disableClearable
                                disableCloseOnSelect
                                value={saveClinic.clinicName}
                                onChange={handleClinics}
                                getOptionLabel={(data) => `${data.clinicName}`}
                                renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.clinicName}</Box>)}
                                options={clinic}
                                noOptionsText={"Clinic not available"}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Clinic Name"
                                    />}
                            />
                        </div>

                        <div className='mt-2'>
                            <label><b>Recived Date</b></label>
                            <div className="form-group">
                                <DatePicker
                                    className="datepicker"
                                    onChange={addRcvDatePicker}
                                    value={rcvDate}
                                    clearIcon={null}
                                />
                            </div>

                        </div>
                        {/* <div>
                        <label><b>Document</b></label>
                        <div >
                            <input style={{ width: 220 }} type="file" multiple onChange={handleFileChange} />
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>
            <div className="text-center mt-2 add_top_30">
                <MainButtonInput  >Save</MainButtonInput>
            </div>
        </form>
    )
} 