import React, {  useState } from "react";
import { MainInput } from "../../../mainComponent/mainInput";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import ServicesApi from "../../../services/ServiceApi";

const Treatment = (props) => {
    const { doctorId } = props
    const [treatment, setTreatment] = useState([]);
    const { AddServices } = ServicesApi()

    function handleChange(event) {
        const { name, value } = event.target;
        setTreatment(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    const Save = (e) => {
        e.preventDefault();
        const bodyData = {
            doctorId: doctorId,
            name: treatment.name,
            fees: treatment.fees
        }

        AddServices(bodyData)
            .then((res) => {
                props.onSubmit()
            });
    }

    return (
        <div className="width_100">
            <form onSubmit={Save}>
                <div className="form-group">
                    <label><b>Treatment Name</b></label>
                    <MainInput
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={treatment.name}
                        placeholder="Enter Treatment name">
                    </MainInput>
                </div>

                <label><b>Fees</b></label>
                <MainInput
                    type="text"
                    name="fees"
                    value={treatment.fees}
                    onChange={handleChange}
                    placeholder="Enter fees">
                </MainInput>

                <div className="text-center m-3">
                    <MainButtonInput value="Save" />
                </div>
            </form >
        </div >
    );
};
export { Treatment }
