import React, { useState } from 'react'
import { MainInput } from '../../../../mainComponent/mainInput';
import { Button } from 'react-bootstrap'
import LabWorkApi from '../../../../services/LabWorkApi';

export default function AddWork(props) {
    const { onClick } = props;
    const { addWorkType } = LabWorkApi()
    const [work, setWork] = useState('')

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWork({ ...work, [name]: value });
    }

    const AddData = () => {
        const bodyData = {
            "worktype": work.worktype,
            "price": work.price,
        }
        addWorkType(bodyData)
        onClick()
    }
    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <label><b>Work Type</b></label>
                </div>
                <div className="col-md-7 ">
                    <MainInput
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={work.worktype}
                        name="worktype"
                        placeholder='Work type'
                    >
                    </MainInput>

                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label><b>Work Type Price</b></label>
                </div>
                <div className="col-md-7 ">
                    <MainInput
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={work.price}
                        name="price"
                        placeholder='Price'
                    >
                    </MainInput>
                </div>
            </div>

            <Button
                type="submit"
                onClick={AddData}
                variant="default"
                className='appColor btn_sub mr-3'>
                Add
            </Button>

        </>
    )
}