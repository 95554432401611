import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import ReportApi from '../../../services/ReportApi';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function NewFollowup(props) {
    const { reportId } = props;
    const { insertNewFollowUpDate } = ReportApi()
    const [date, setDate] = useState();
    const navigate = useNavigate()

    const handleShow = () => {
        navigate(`summary`)
    }

    const addDatePicker = (date) => {
        setDate(date)
    }

    const addNode = () => {
        const bodyData = {
            "new_follow_up": date,
        }
        insertNewFollowUpDate({ reportId }, bodyData)
        toast.success("Saved Successfully!")
    }

    return (
        <div >
            <div className="row">
                <div className="col-lg-2">
                    <div className="form-group">
                        <b>Follow-Up:</b>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group">
                        <DatePicker
                            className="datepicker"
                            onChange={addDatePicker}
                            value={date}
                            clearIcon={null}
                        />
                    </div>
                </div>
            </div>
            <div className="text-right add_top_30">
                <input
                    type="submit"
                    onClick={addNode}
                    className="btn_1 medicinebtn"
                    value="Add"
                />
                <input
                    type="submit"
                    className="btn_1 medicinebtn"
                    value="Summary"
                    onClick={handleShow}
                />
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
        </div>

    )
}