import React, { useState, useEffect } from "react";
import avatarImage from '../../../img/profile.png'
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from '../../../mainComponent/mainInput';
import AuthApi from "../../../services/AuthApi";
import uuid from "uuid";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Toaster from "../../Toaster";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { setsubscriptionId } from '../../../recoil/atom/setSubscriptionId';
import SubscriptionApi from '../../../services/SubscriptionApi';
import { useFormik } from "formik";
import { DataValidation } from "../../../schemas/validationdata";

const initialValues = {
    gender: "",
    name: "",
    personalEmail: "",
    address: "",
}

function DoctorPersonalInformation(props) {
    const { data, doctorId } = props;
    const [updateData, setUpdateData] = useState([]);
    const [subscriptionId] = useRecoilState(setsubscriptionId)
    const {
        addDoctorInformation,
        submitDoctorInformation
    } = AuthApi();

    const { subscriptionPDF } = SubscriptionApi();

    async function uploadImageAsync(uri) {
        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", uri, true);
            xhr.send(null);
        });
        const fileRef = ref(getStorage(), uuid.v4());
        const result = await uploadBytes(fileRef, blob);
        return await getDownloadURL(fileRef);
    }


    // handleSubmit
    const { values, errors, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: DataValidation,
        onSubmit: async (formValues, { setSubmitting }) => {
            try {
                const resultUrl = await uploadImageAsync(updateData.photo);
                const bodyData = {
                    photo: resultUrl,
                    name: formValues.name,
                    gender: updateData.gender,
                    personalEmail: formValues.personalEmail,
                    address: formValues.address,
                }
                await submitDoctorInformation({ doctorId, bodyData });
                await subscriptionPDF(subscriptionId);
            } 
            catch (error) {
                toast.error("Failed to save data!");
            } finally {
                setSubmitting(false);
            }
            // toast.success("Saved Successfully!");
        }
    });

    useEffect(() => {
        addDrInfo()
        if (updateData.gender) {
            setFieldValue('gender', updateData.gender);
        }
    }, [])
    
    const handleInputRadio = (e) => {
        const { name, value } = e.target;
        setUpdateData({ ...updateData, [name]: value });
    }

    const addDrInfo = () => {
        addDoctorInformation({ doctorId })
            .then(jsonRes => {
                setUpdateData(jsonRes)
            });
    }

    return (
        <form encType='multipart/form-data' onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6 ">
                    <div className="row mb-10">
                        <div className="col-md-6">
                            <div className="doctorphoto">
                                {updateData.photo ?
                                    <img
                                        src={updateData.photo}
                                        className="doctorphotoStyle"
                                        alt="doctorPhoto"
                                    />
                                    : <img
                                        src={avatarImage}
                                        alt="doctorPhoto"
                                        className="doctorphotoStyle"
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-left">
                                <label htmlFor="photo"><b>Doctor photo</b></label>
                                <MainInput
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => {
                                        setUpdateData({ ...updateData, ['photo']: URL.createObjectURL(e.target.files[0]) })
                                    }}
                                    name="photo"
                                >
                                </MainInput>
                            </div>
                        </div>
                    </div>
                    <div className="text-left">
                        <label htmlFor="gender"><b>Gender *</b></label>
                    </div>
                    <div className="radioButton" align="left">
                        <input
                            className="radio_button"
                            type="radio"
                            value="female"
                            name="gender"
                            onChange={handleInputRadio}
                            checked={updateData.gender === 'female' ? true : false}
                        />
                        <span>Female</span>
                        <input
                            className="radio_button"
                            type="radio"
                            value="male"
                            name="gender"
                            checked={updateData.gender === 'male' ? true : false}
                            onChange={handleInputRadio}
                        />
                        <span>Male</span>
                        <input
                            className="radio_button"
                            type="radio"
                            value='other'
                            name="gender"
                            checked={updateData.gender === 'other' ? true : false}
                            onChange={handleInputRadio}
                        />
                        <span>Other</span>
                        {errors.gender !== "" ? errors.gender && <span className="validation">Please Select your gender</span> : null}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="text-left">
                        <label htmlFor="name"><b>Full Name *</b></label>
                    </div>
                    <MainInput
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange}
                        >
                        {errors.name ? (<span className="validation">{errors.name}</span>) : null}
                    </MainInput>
                    <div className="text-left">
                        <label htmlFor="personalEmail"><b>Personal EmailId *</b></label>
                    </div>
                    <MainInput
                        name="personalEmail"
                        placeholder="Personal EmailId"
                        value={values.personalEmail}
                        onChange={handleChange}
                        >
                        {errors.personalEmail ? (<span className="validation">{errors.personalEmail}</span>) : null}
                    </MainInput>
                    <div className="text-left">
                        <label ><b>City & Area *</b></label>
                    </div>
                    <div align='left'>
                        <MainInput
                            name='address'
                            value={values.address}
                            placeholder="Address"
                            onChange={handleChange}>
                            {errors.address ? (<span className="validation">{errors.address}</span>) : null}
                        </MainInput>
                    </div>
                </div>
            </div>
            <div className="row float-right">
                <div className="text-left m-2 add_top_30">
                    <MainButtonInput > Save</MainButtonInput>
                </div>
                <div className="text-left m-2 add_top_30">
                    <MainButtonInput onClick={data}>Next</MainButtonInput>
                </div>
                <Toaster />
            </div>
        </form>
    )
}
export { DoctorPersonalInformation }