import React, { useEffect, useState } from "react";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { Button, Modal } from "react-bootstrap";
import { MainSelect } from "../../../mainComponent/mainSelect";
import LabWorkApi from "../../../services/LabWorkApi";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import LabWorkView from "./LabWorkView";

const exportToCSV = (csvData) => {
    // Extracting the column headers from the first object
    const columns = Object.keys(csvData[0]);
    // Generating the CSV header
    let csvContent = columns.join(",") + "\n";
    // Generating CSV rows
    csvData.forEach(item => {
        const row = columns.map(column => {
            const value = item[column].toString().replace(/"/g, '""');
            return `"${value}"`;
        }).join(",");
        csvContent += row + "\n";
    });

    // Creating the CSV file
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
}

export default function LabWorkList(props) {
    const { doctorId } = props;
    const [labWorkData, setLabWorkData] = useState(null)
    const [labs, setLabs] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedLab, setSelectedLab] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const [showData, setShowData] = useState(false);
    const [labWorkId, setLabWorkId] = useState(false);

    const pageSize = 5;

    useEffect(() => {
        getLabWorkData(currentPage);
    }, [currentPage, selectedLab, selectedDateRange])

    const handleLabChange = (e) => {
        setSelectedLab(e.target.value);
    };

    const handleDataClose = () => setShowData(false)

    const onDataFormSubmit = () => {
        handleDataClose()
    };

    // Function to handle date range change
    const handleDateRangeChange = (newValue) => {
        if (newValue[0] && newValue[1]) {
            setSelectedDateRange(newValue);
        }
    };

    const { getlabworkbydoctorId } = LabWorkApi()

    const getLabWorkData = (currentPage) => {
        let filters = {
            ...(selectedDateRange[0] && { startDate: selectedDateRange[0] }),
            ...(selectedDateRange[1] && { endDate: selectedDateRange[1] }),
            ...(selectedLab && { selectedLab: selectedLab }),
        }
        getlabworkbydoctorId(doctorId, currentPage, pageSize, filters)
            .then((result) => {
                const lablist = result.result
                setLabWorkData(result.data)
                const res = lablist.filter(function (item, index) {
                    return index === lablist.findIndex((obj) => {
                        if (item.labname === obj.labname)
                            return item
                    })
                })
                setLabs(res)
            })
    }

    const handleExport = () => {
        exportToCSV(labWorkData);
    };

    return (
        <>
            <Wrapper>
                <div className="white-box">
                    <div className='row' align='left'>
                        <div className='col-md-4 '>
                            <label className='mb-2' value="lab"><b>Select Lab</b></label>
                            <div className='mt-2'>
                                <MainSelect value={selectedLab} onChange={handleLabChange}>
                                    <option value="">Select Lab</option>
                                    {labs.map((item, index) => (
                                        <option key={index} value={item.labname} className="form-control">{item.labname}</option>
                                    ))}
                                </MainSelect>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <label value="month"><b>Select Date Range</b></label>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateRangePicker']}>
                                        <DateRangePicker
                                            value={selectedDateRange}
                                            onChange={handleDateRangeChange}
                                            localeText={{ start: 'Start', end: 'End' }} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='col-md-4' align='right'>
                            <label value="month"><b>Download</b></label>
                            <div className='mt-2'>
                                <Button
                                    type="submit"
                                    onClick={handleExport}
                                    variant="default"
                                    className='lightbuttonColor  mr-3 btn_sub'>
                                    <i className="icon-download-1" style={{ fontSize: 15 }} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            <div className="modalbtn">
                <Modal show={showData} onHide={handleDataClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LabWorkView labWorkId={labWorkId} onSubmit={onDataFormSubmit} />
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}