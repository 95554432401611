import { Link, useParams } from "react-router-dom";
import { MainNav } from "../../mainComponent/mainNav";
import UserLinks from "./partial/uselinks";
import { Wrapper } from "../../mainComponent/Wrapper";
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

import ClinicApi from "../../services/ClinicApi";
import ReactPaginate from "react-paginate";
import Loader from "../../common/Loader";
import { ClinicRegisterModal } from "./ClinicRegisterModal";


export default function ClinicRegistration() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [show, setShow] = useState(false);
    const [clinicData, setClinicData] = useState(null)
    console.log('====clinicData====', clinicData)
    const [filterData, setFilterData] = useState([])
    console.log('====filterData====', filterData)
    const { getregisterclinic } = ClinicApi()
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchData()
    }, [currentPage]);

    setTimeout(() => {
        setIsLoading(false);
    }, 2000);

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    const pageSize = 6
    const fetchData = () => {
        getregisterclinic(currentPage, pageSize)
            .then((res) => {
                setFilterData(res.doc)
                setClinicData(res.clinicData)
                setTotalPages(res.clinicListPages)
            })
    }

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
    }

    const searchClinic = (value) => {
        if (value.length !== 0) {
            const res = filterData.filter(name => name.clinicName.toLowerCase().includes(value.toLowerCase()))
            setClinicData(res)
        }
        else {
            setClinicData(clinicData)
        }
    }

    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50 mt-1 ">
                        <span className='float-none   ml-2' style={{ fontSize: 'inherit' }}>Clinic Resistration</span>
                    </div>
                    <div className="width50 row justifyContent">
                        <Button
                            type="submit"
                            variant="default"
                            onClick={handleShow}
                            className='appColor btn_sub'>
                            register Clinic
                        </Button>
                    </div>
                </div>
            </MainNav>
            <div className='row'>
                <div className="width16">
                    <div className="dash row">
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module} />
                    </div>
                </div>
                <div className="width84">
                    <div className='common_box'>
                        <div className="m-2" align='right'>
                            <div className="width50 ml-2 mr-2 row justifyContent">
                                <div id="custom-search-input">
                                    <input type="text"
                                        onChange={(e) => searchClinic(e.target.value)}
                                        className="search-query"
                                        placeholder="Search clinic by name"
                                    />
                                </div>
                            </div>
                        </div>
                        <>
                            {isLoading ?
                                <div className='loader-container'>
                                    <Loader />
                                </div>
                                :
                                <>
                                    <div className='row'>
                                        {clinicData && clinicData.map((details, i) => {
                                            return (
                                                <div key={i} className="col-md-4">
                                                    <div className="cardDiv">
                                                        <div className='doctorCard'>
                                                            <div className='row'>
                                                                <div className='col-md-5'>
                                                                    <img
                                                                        src={details.clinicLogo}
                                                                        alt="doctorProfile"
                                                                        className='doctorphotoPatient'
                                                                    />
                                                                </div>
                                                                <div className='col-md-7 ' align='center'>
                                                                    <span className='patientName fontS font-weight'>
                                                                        {details.clinicName.charAt(0).toUpperCase() + details.clinicName.slice(1)}
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className='cardSpan'>
                                                            <span className="fontSize ">
                                                                <b>Address : </b> {details.address}
                                                            </span>
                                                        </span>
                                                        <span className='cardSpan'>
                                                            <span className=' fontSize '>
                                                                <b>Clinic Number : </b>{details.clinicNumber}
                                                            </span>
                                                        </span>
                                                        <div className='appointmentBtn' align='right'>
                                                            <Link >
                                                                <button className="btn appColor helperBtn">Attached Doctor</button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {clinicData ?
                                        <div>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={totalPages}
                                                previousLabel="< Previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination "
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                            />
                                        </div>
                                        : <div className="clinicHistory" ><b>Data is not Available</b></div>}
                                </>
                            }
                        </>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title >register Clinic</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ClinicRegisterModal
                                    doctorId={doctorId}
                                    onSubmit={handleClose} />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}