
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import ReactPaginate from "react-paginate";
import { Treatment } from "./treatment";
import ServicesApi from "../../../services/ServiceApi";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function AddTreatment(props) {
    const { doctorId } = props
    const [showTreatment, setShowTreatment] = useState(false);
    const [services, setServices] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { getServices } = ServicesApi()
    const pageSize = 5;

    useEffect(() => {
        fetchServices(currentPage)
    }, [currentPage, services])

    const handleTreatmentClose = () => setShowTreatment(false)
    const handleTreatmentShow = () => setShowTreatment(true)

    const onCTreatmentFormSubmit = () => {
        handleTreatmentClose()
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }
    const fetchServices = (() => {
        getServices(doctorId, currentPage, pageSize)
            .then((res) => {
                setServices(res.result)
                setTotalPages(res.totalPages)
            })
    })

    return (
        <div >
            <div className="modalbtn">
                <Modal show={showTreatment} onHide={handleTreatmentClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Treatment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Treatment doctorId={doctorId} onSubmit={onCTreatmentFormSubmit} />
                    </Modal.Body>
                </Modal>
            </div>

            <div align='right'>
                <MainButtonInput className='align-left' onClick={handleTreatmentShow}>ADD TREATMENT</MainButtonInput>
            </div>

            <TableContainer component={Paper}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><b>Sr. No.</b></TableCell>
                            <TableCell align="left"><b>service Name</b></TableCell>
                            <TableCell align="left"><b>Fees </b></TableCell>
                        </TableRow>
                    </TableHead>
                    {services ?
                        <TableBody>
                            {services && services.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="left">
                                            {index + 1}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.name}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.fees}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        :
                        <div align="center"><b>records empty </b></div>
                    }
                </Table>
            </TableContainer >
            {services  ?
                <div className="mt-5">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination "
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
                : null}
        </div >
    )
}

export { AddTreatment }