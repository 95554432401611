import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactPaginate from "react-paginate";
import LabWorkApi from '../../../services/LabWorkApi';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Modal } from "react-bootstrap";
import LabWorkView from '../labwork/LabWorkView';

export default function GetLabWork(props) {
    const { doctorId } = props
    const [labWorkData, setLabWorkData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [showData, setShowData] = useState(false);
    const [labWorkId, setLabWorkId] = useState(false);
    const pageSize = 5;
    const { getlabworkbydoctorId } = LabWorkApi()

    useEffect(() => {
        getLabWorkData(currentPage);
    }, [currentPage,labWorkData])

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }

    const handleDataClose = () => setShowData(false)

    const handleDataShow = (id) => {
        setLabWorkId(id)
        setShowData(true)
    }

    const onDataFormSubmit = () => {
        handleDataClose()
    };

    const getLabWorkData = (currentPage) => {
        getlabworkbydoctorId(doctorId, currentPage, pageSize)
            .then((result) => {
                setTotalPages(result.totalPages)
                setLabWorkData(result.data)
            })
    }
    return (
        <>
            {labWorkData ? <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><b>Work Name</b></TableCell>
                                <TableCell align="center"><b>Work Type</b></TableCell>
                                <TableCell align="center"><b>Lab Name</b></TableCell>
                                <TableCell align="center"><b>Lab Contact</b></TableCell>
                                <TableCell align="center"><b>Given Date</b></TableCell>
                                <TableCell align="center"><b>View Details</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {labWorkData && labWorkData.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            {item.workname}
                                        </TableCell>

                                        <TableCell align="center">
                                            {item.worktype}
                                        </TableCell>

                                        <TableCell align="center">
                                            {item.labname}
                                        </TableCell>

                                        <TableCell align="center">
                                            {item.labcontact}
                                        </TableCell>

                                        <TableCell align="center">
                                            {moment(item.givendate).format("DD-MM-YYYY")}
                                        </TableCell>

                                        <TableCell align="center">
                                            <Link onClick={() => handleDataShow(item._id)}>
                                                <VisibilityIcon style={{ fontSize: 20 }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer >
                <div className='mt-5'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination "
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                    />
                </div>
            </> : null}
            <Modal show={showData} onHide={handleDataClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Work Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabWorkView labWorkId={labWorkId} onSubmit={onDataFormSubmit} />
                </Modal.Body>
            </Modal>

        </>
    )
} 