import { Link, useParams, useNavigate } from "react-router-dom";
import { PatientRegistrationForm } from "../patient/patientRegistrationForm";
import { Wrapper } from "../mainComponent/Wrapper";
import { MainNav } from "../mainComponent/mainNav";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import AuthApi from "../services/AuthApi";
import { useEffect, useState } from "react";

export default function CreatePatientProfile() {
    const navigate = useNavigate()
    const { patientId } = useParams()
    const { getDrInfo } = AuthApi()
    const [doctorName, setDoctorsName] = useState([])
    const [doctorId, setDoctorsId] = useRecoilState(setDoctorId)

    useEffect(() => {
        doctorInfo()
    }, [])

    function handalChange() {
        navigate(`/doctors/patient/${doctorId}/patientprofile/${patientId}`)
    }
    const doctorInfo = () => {
        getDrInfo({ doctorId })
            .then((res) => {
                setDoctorsName(res.result[0].name)
            })
    }

    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50">
                        <Link to={`/doctors`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                        <span className='float-none ml-2' style={{ fontSize: 'inherit' }}>Walkin Patient</span>
                    </div>
                    <div className="width50 row justifyContent">
                        <div className="appColor normal-font" align='right'>Dr. {doctorName}</div>
                    </div>
                </div>
            </MainNav>
            <div className='row'>
                <div className="width16">
                    <div className="dash row">
                        <UserLinks />
                    </div>
                </div>
                <div className="width84">
                    <div className="container margin_60">
                        <div className="patientFetch">
                            <div className="Form-data">
                                <div className="box_general_3">
                                    <PatientRegistrationForm patientId={patientId} handalChange={handalChange} />
                                </div>
                            </div>
                            {/* <DoctorBookingConfirmation doctorId={doctorId} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}