import { Button, Modal } from "react-bootstrap";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { Wrapper } from "../mainComponent/Wrapper";
import { MainNav } from "../mainComponent/mainNav";
import { AddClinic } from "./addClinic";
import { useEffect, useState } from "react";
import ClinicApi from "../services/ClinicApi";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import ViewClinic from "./partial/ViewClinic";
import { EditClinic } from "./partial/EditClinic";
import Loader from "../common/Loader";

export default function Clinic() {
    const [clinicData, setClinicData] = useState([])
    const [clinicList, setClinicList] = useState([])
    const { getClinic } = ClinicApi();
    const [show, setShow] = useState(false);
    const [clinicShow, setClinicShow] = useState(false);
    const [clinicInfo, setClinicInfo] = useState(false);
    const [editClinicShow, setEditClinicShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [filterData, setFilterData] = useState([])
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchClinic(currentPage)
    }, [currentPage]);

    setTimeout(() => {
        setIsLoading(false);
    }, 2000);

    const ClinicsShow = (details) => {
        setClinicShow(true);
        setClinicInfo(details)
    }
    const ClinicsClose = () => setClinicShow(false);

    const EditClinicShow = (details) => {
        setEditClinicShow(true);
        setClinicInfo(details)
    }
    const EditClinicClose = () => setEditClinicShow(false);

    const onClinicFormSubmit = () => {
        handleClose();
    };

    const pageSize = 6;
    const fetchClinic = () => {
        getClinic(currentPage, pageSize)
            .then((res) => {
                setTotalPages(res.clinicListPages)
                setClinicData(res.clinicList)
                setClinicList(res.clinicList)
                setFilterData(res.result)
            })
    }
    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1);
    }
    const searchClinic = (value) => {
        if (value.length !== 0) {
            const res = filterData.filter(name => name.clinicName.toLowerCase().includes(value.toLowerCase()))
            setClinicData(res)
        }
        else {
            setClinicData(clinicList)
        }
    }
    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50 mt-1">
                        <span style={{ fontSize: 'inherit' }}>All Clinics</span>
                    </div>
                    <div className="width50 row justifyContent">
                        <Button
                            type="submit"
                            onClick={() => handleShow()}
                            variant="default"
                            className='appColor btn_sub'>
                            Register Clinic
                        </Button>
                    </div>
                </div>
            </MainNav>
            <div className='row'>
                <div className="width16">
                    <div className="dash row">
                        <UserLinks />
                    </div>
                </div>
                <div className="width84">
                    <div className="common_box">
                        <div className="m-2" align='right'>
                            <div className="width50 ml-2 mr-2 row justifyContent">
                                <div id="custom-search-input">
                                    <input type="text"
                                        onChange={(e) => searchClinic(e.target.value)}
                                        className="search-query"
                                        placeholder="Search clinic by name"
                                    />
                                </div>
                            </div>
                        </div>
                        <>
                            {isLoading ?
                                <div className='loader-container'>
                                    <Loader />
                                </div>
                                :
                                <>
                                    <div className='row'>
                                        {clinicData.map((details, i) => {
                                            return (
                                                <div key={i} className="col-md-4">
                                                    <div className="cardDiv">
                                                        <div className='doctorCard'>
                                                            <div className='row'>
                                                                <div className='col-md-5'>
                                                                    <img
                                                                        src={details.clinicLogo}
                                                                        alt="doctorProfile"
                                                                        className='doctorphotoPatient'
                                                                    />
                                                                </div>
                                                                <div className='col-md-7 ' align='center'>
                                                                    <span className='patientName fontS font-weight'>
                                                                        {details.clinicName.charAt(0).toUpperCase() + details.clinicName.slice(1)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className='cardspan '>
                                                            <span className="fontSize padding-left-10">
                                                                <b>Address: </b>
                                                            </span>
                                                            <span align='left'>{details.address}</span>
                                                        </span>
                                                        <span className='cardspan'>
                                                            <span className='padding-left-10 fontSize '>
                                                                <b>Clinic Number : </b>{details.clinicNumber}
                                                            </span>
                                                        </span>
                                                        <div className=' appointmentBtn' align='right'>
                                                            <Link >
                                                                <button onClick={() => ClinicsShow(details)} className="btn appColor helperBtn ">View More</button>
                                                            </Link>
                                                            <Link  >
                                                                <button onClick={() => EditClinicShow(details)} className='btn btn-default btnMargin ' >Edit Clinic</button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {clinicData ?
                                        <div>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={totalPages}
                                                previousLabel="< Previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination "
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                            />
                                        </div>
                                        : <div className="clinicHistory" ><b>Data is not Available</b></div>}
                                </>
                            }
                        </>

                    </div>
                </div>
            </div>
            <div className="modalbtn">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Clinic</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddClinic onSubmit={onClinicFormSubmit} />
                    </Modal.Body>
                </Modal>
            </div>
            <Modal show={clinicShow} onHide={ClinicsClose}>
                <Modal.Header closeButton>
                    <Modal.Title >Clinic Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ViewClinic clinicData={clinicInfo} onSubmit={onClinicFormSubmit} />
                </Modal.Body>
            </Modal>
            <div className="modalbtn">
                <Modal show={editClinicShow} onHide={EditClinicClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Clinic</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditClinic clinicsData={clinicInfo} onSubmit={EditClinicClose} />
                    </Modal.Body>
                </Modal>
            </div>
        </Wrapper>
    )

}