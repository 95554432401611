import React, { useEffect } from "react";
import { Wrapper } from "../../mainComponent/Wrapper";
import { MainNav } from "../../mainComponent/mainNav";
import UserLinks from "./partial/uselinks";
import AuthApi from "../../services/AuthApi";
import { useState } from "react";
import { Icon } from "@mui/material";
import UpgradeSubscription from "./partial/upgradeSubscription";
import ReactPaginate from "react-paginate";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";

export default function DoctorList() {
    const [doctorData, setDoctorData] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [key, setkeys] = useState('');
    const { getdoctors } = AuthApi();
    const navigate = useNavigate();

    useEffect(() => {
        getDoctorList(currentPage, key)
    }, [currentPage]);

    setTimeout(() => {
        setIsLoading(false);
    }, 2000);

    const pageSize = 6;
    const getDoctorList = (currentPage, key) => {
        getdoctors(currentPage, pageSize, key)
            .then((result) => {
                setFilterData(result.result)
                setDoctorData(result.doctorList)
                setTotalPages(result.doctorListPages)
            })
    }

    const searchDoctor = (value) => {
        setkeys(value)
        if (value.length !== 0) {
            const res = filterData.filter(name => name.name.toLowerCase().includes(value.toLowerCase()))
            setDoctorData(res)
        }
        else {
            setDoctorData(doctorData)
        }
    }

    const handleShowProfile = (details, e) => {
        e.preventDefault();
        navigate(`profile/${details._id}`)
    }

    const BookAppointments = (details, e) => {
        e.preventDefault();
        if (details.isSubscribed === false) {
            navigate(`/subscriptions/${details._id}`);
        } else {
            navigate(`patient/${details._id}`)
        }
    }

    const ViewAppointments = (details, e) => {
        e.preventDefault();
        navigate(`appointment/${details._id}`)
    }
    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }

    return (
        <Wrapper>
            <MainNav>
                <div className=" clearfix row">
                    <div className="width50">
                        <span className='float-none margin-top' style={{ fontSize: 'inherit' }}>Doctor-List</span>
                    </div>
                    <div className="width50 row justifyContent">
                        <div >
                            <NavLink to={`/doctors/addnewdoctor`} >
                                <Icon className="addiconbutton" style={{ fontSize: 50 }}>add</Icon>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </MainNav>
            <div className='row'>
                <div className="width16">
                    <div className="dash row">
                        <UserLinks />
                    </div>
                </div>
                <div className="width84">
                    <div className="common_box">
                        <div className="m-2" align='right'>
                            <div className="width50 ml-2 mr-2 row justifyContent">
                                <div id="custom-search-input">
                                    <input type="text"
                                        onChange={(e) => searchDoctor(e.target.value)}
                                        className="search-query"
                                        placeholder="Search Doctor By Name"
                                    />
                                </div>
                            </div>
                        </div>
                        <>
                            {isLoading ?
                                <div className='loader-container'>
                                    <Loader />
                                </div>
                                :
                                <>
                                    <div className='row'>
                                        {doctorData && doctorData.map((details, i) => {
                                            return (
                                                <div key={i} className="col-md-4 ">
                                                    <div className="cardDiv">
                                                        <span className='cardSpan'>
                                                            <i className='icon-user color patientListIcon' />
                                                            <span align='left' className='patientName'>
                                                                <NavLink to="#" className='underLine' onClick={(e) => handleShowProfile(details, e)}>
                                                                    Dr.{details.name}
                                                                </NavLink>
                                                            </span>
                                                        </span>
                                                        <span className='cardSpan  text-align'>
                                                            <i className='icon-building color patientListIcon' />
                                                            <span className='patinetInfo'>{details.address}</span>
                                                        </span>
                                                        <UpgradeSubscription doctorId={details._id} />
                                                        <div className=' appointmentBtn'>
                                                            <NavLink onClick={(e) => BookAppointments(details, e)}>
                                                                <button className='btn appColor helperBtn'>Book Appointment</button>
                                                            </NavLink>
                                                            <NavLink onClick={(e) => ViewAppointments(details, e)}>
                                                                <button className='btn appColor helperBtn'>View Appointments</button>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {doctorData ?
                                        <div>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={totalPages}
                                                previousLabel="< Previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination "
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                            />
                                        </div>
                                        : null}
                                </>
                            }
                        </>
                    </div >
                </div>
            </div>
            <Outlet />
        </Wrapper >
    )

}