import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchDoctorPersonalDetails } from "./Personal/Partial/fetchDoctorPersonalDetails";
import { MainNav } from "../../mainComponent/mainNav";
import UserLinks from "../Dashboard-card/partial/uselinks";
import { Wrapper } from "../../mainComponent/Wrapper";
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";
export default function DoctorProfile() {
    
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/doctors/helper/${doctorId}`)
    }

    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50 mt-1">
                        <Link
                            to={`/doctors`}>
                            <i className="arrow_back backArrow" title="back button"> </i>
                        </Link>
                        <span className='float-none ml-2' style={{ fontSize: 'inherit' }}>General info</span>
                    </div>
                    <div className="width50 row justifyContent">
                        <Button
                            type="submit"
                            variant="default"
                            onClick={handleClick}
                            className='appColor btn_sub mr-3'>
                            Add Assistant
                        </Button>
                        <Link className="mr-3 mt-1" to={"calendar"}>
                            <span> <i className="icon-calendar-6" title="Calendar"></i></span>
                        </Link>
                        <Link className="mt-1" to={"edit"}>
                            <span> <i className="icon_pencil-edit" title="Edit profile"></i></span>
                        </Link>

                    </div>
                </div>
            </MainNav>
            <div className='row'>
                <div className="width16">
                    <div className="dash row">
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module} />
                    </div>
                </div>
                <div className="width84">
                    <div className='common_box'>
                        <div className="white-box">
                            <div id="section_1" >
                                <FetchDoctorPersonalDetails doctorId={doctorId} />
                            </div>
                            <div className="row right ">
                                <div className=" radius labbtn appColor buttonPatient" align='center'>
                                    <Link to={`addlab/${doctorId}`} className="btn">
                                        <span className=" appColor">Lab Work</span>
                                    </Link>
                                </div>
                                <div className="radius labbtn appColor buttonPatient" align='center'>
                                    <Link to={`paymentlist/${doctorId}`} className="btn">
                                        <span className=" appColor">Payment Deatails</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}