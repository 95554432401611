import axios from 'axios';
import { API } from '../config';

export default function ServicesApi() {
    const AddServices = async (bodydata) => {
        try {
            const result = await axios.post(`${API}/addservices`, bodydata)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getServices = async (doctorId, currentPage, pageSize) => {
        try {
            const result = await axios.get(`${API}/getservices/${doctorId}?page=${currentPage}&pageSize=${pageSize}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const payment = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/razorpay/order`, bodyData)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const refund = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/refund`, bodyData)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const paymentCapture = async (details) => {
        try {
            const result = await axios.post(`${API}/paymentCapture`, details)
            return result.data;
        }
        catch (err) {
            return err
        }
    };

    const getPaymentDetails = async ( doctorId, currentPage, pageSize) => {
        try {
            const result = await axios.get(`${API}/paymentData/${doctorId}?page=${currentPage}&pageSize=${pageSize}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    return {
        AddServices,
        getServices,
        payment,
        paymentCapture,
        refund,
        getPaymentDetails
    }
}