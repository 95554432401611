import React from 'react';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ReportApi from '../../../services/ReportApi';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import GetDentalServices from './GetDentalServices';
import { Box} from '@mui/material';
import ServicesApi from '../../../services/ServiceApi';

export default function Treatment(props) {
    const { onChange, reportId, doctorId } = props
    const { insertDentalServices } = ReportApi()
    const [services, setGetServices] = useState([]);
    const [saveServices, setSaveservices] = useState([])
    const { getServices } = ServicesApi()

    useEffect(() => {
        getservicesData();
    }, [])

    const getservicesData = () => {
        getServices(doctorId)
            .then((result) => {
                setGetServices(result.doc)
            })
    };

    const handleDataSave = (e, selectedData) => {
        e.preventDefault()
        setSaveservices(selectedData)
    }
    const DataSave = () => {
        const bodyData = {
            "reportId": reportId,
            "service_name": saveServices.name,
            "fees": saveServices.fees
        }
        insertDentalServices(bodyData)
        toast.success("Saved Successfully!")
    }
    //git format-patch -1 48f43bf5489d8e76c198ea86095fa217e5688841


    return (
        <>
            <div className='row' >
                <div className='col-md-6'>
                    <label className='left'>Services</label>
                    <Autocomplete
                        style={{ width: 200 }}
                        // id={WokType._id}
                        disablePortal={true}
                        disableClearable
                        disableCloseOnSelect
                        value={saveServices.name}
                        onChange={handleDataSave}
                        getOptionLabel={(data) => `${data.name}`}
                        options={services}
                        renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.name}</Box>)}
                        renderInput={(params) =>
                            <TextField {...params}
                                label="Choose service"
                            />}
                    />
                </div>

                <div className='col-md-6 '>
                    <GetDentalServices reportId={reportId} />
                </div>

            </div>
            <div>
                <div className="text-right add_top_30 ">
                    <input
                        type="submit"
                        onClick={DataSave}
                        className="btn_1 "
                        value="Add"
                    />
                    <input
                        type="submit"
                        onClick={onChange}
                        className="btn_1 medicinebtn"
                        value="Next"
                    />
                </div>
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
        </>
    )
}