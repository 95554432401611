import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { MainButtonInput } from "../../mainComponent/mainButtonInput";
import { MainInput } from "../../mainComponent/mainInput";
import AuthApi from "../../services/AuthApi";
import { useRecoilState } from "recoil";
import { setloggedIn } from '../../recoil/atom/setloggedIn';

export default function LoginAdmin() {
    const [loginData, setLoginData] = useState("");
    const [loggedIn, setLoggedIn] = useRecoilState(setloggedIn)
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate()
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    }
    const { loginAdmin } = AuthApi();

    const loginAdminData = (e) => {
        e.preventDefault();
        const bodyData = {
            "username": loginData.username,
            "password": loginData.password
        }
        loginAdmin(bodyData)
            .then((data) => {
                if (data.error === "user with that email does not exist.") {
                    setIsError(true)
                }
                else {
                    navigate(`/doctors`)
                    setLoggedIn("loggedIn")
                }
            })
    }

    return (
        <div>
            <main>
                <div className="bg_color_2">
                    <div className="container margin_60_35">
                        <div id="login-2">
                            <h1> Login to Wecurify Admin</h1>
                            <form >
                                <div className="box_form clearfix">
                                    <div className="box_login last">
                                        <div className="row">
                                            <div className=" col-md-12 ">
                                                <MainInput
                                                    className="form-control"
                                                    type="text"
                                                    name="username"
                                                    onChange={handleChange}
                                                    placeholder="Enter your name" >
                                                </MainInput>
                                            </div>
                                            <div className=" col-md-12 ">
                                                <MainInput
                                                    className="form-control"
                                                    type="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    placeholder="Password" >
                                                </MainInput>
                                                {isError === true ? <span className="validation mb-2 ">Please Enter Valid Username And Password</span> : null}
                                            </div>
                                        </div>
                                        <div align='center'>
                                            <MainButtonInput onClick={(e) => loginAdminData(e)}>Login</MainButtonInput>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}