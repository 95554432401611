import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import GetSymptomsData from './GetSymptomsData'
import ReportApi from '../../../services/ReportApi';
import Toaster from '../../Toaster';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function Symptoms(props) {
    const { onChange, reportId } = props
    const [symptoms, setSymptoms] = useState([])
    const [saveSymptoms, setSaveSymptoms] = useState([])
    const [inputSymptom, setInputSymptoms] = useState([])
    const { symptomsData, insertSymptoms, insertSymptom_masterTable } = ReportApi();

    useEffect(() => {
        getSymptomsData();
    }, [])

    const getSymptomsData = () => {
        symptomsData()
            .then((result) => {
                setSymptoms(result)
            })
    };

    const handleChange = (e, selectedValue) => {
        e.preventDefault()
        setSaveSymptoms(selectedValue)
    }

    const addInputBox = () => {
        const value = [...inputSymptom, []]
        setInputSymptoms(value)
    }

    const handleInputChange = (onChangeValue, i) => {
        const inputData = [...inputSymptom]
        inputData[i] = onChangeValue.target.value;
        setInputSymptoms(inputData)
    }
    const handleDelete = (i) => {
        const deleteVal = [...inputSymptom]
        deleteVal.splice(i)
        setInputSymptoms(deleteVal)
    }
    const addSymptoms = () => {
        saveSymptoms.push(...inputSymptom)
        const bodyData = {
            "symptoms": saveSymptoms,
        }
        insertSymptoms({ reportId }, bodyData)
            .then(() => {
                inputSymptom.map((data, i) => {
                    const other = {
                        "symptoms": data,
                    }
                    insertSymptom_masterTable(other)
                })
            })
        toast.success("Saved Successfully!")
    }


    return (
        <div>
            <div className=' row'>
                <div className=' left col-md-6'>
                    <label className='d-flex'>Choose Symptoms</label>
                    <div className='row'>
                        <Autocomplete
                            style={{ width: 250 }}
                            id={symptoms._id}
                            disablePortal={true}
                            disableClearable
                            multiple={true}
                            disableCloseOnSelect
                            value={saveSymptoms.name}
                            onChange={handleChange}
                            options={symptoms.map((n) => `${n.name}`)}
                            noOptionsText={"Symptom not availabel please add"}
                            renderInput={(params, index) =>
                                <TextField
                                    key={index}
                                    {...params}
                                    label="Choose a Symptoms"
                                />}
                        />
                        <div className='ml-2 mt-2'>
                            <Link to="#" onClick={() => addInputBox()}>
                                <AddOutlinedIcon style={{ fontSize: 25, color: '#1a3c8b', marginLeft: 5, marginTop: 5 }} />
                            </Link>
                        </div>
                    </div>
                    <div>
                        {
                            inputSymptom.map((data, i) => {
                                return (
                                    <div key={i} className='row add_top_20 d-flex'>
                                        <span style={{ width: 250 }}>
                                            <input
                                                type="text"
                                                className="form-control "
                                                onChange={(e) => handleInputChange(e, i)}
                                                placeholder="Enter your symptoms"
                                            />
                                        </span>
                                        <span className="width_10">
                                            <Link to="#" onClick={() => handleDelete(i)}>
                                                <DeleteForeverIcon style={{ fontSize: 25, marginTop: 7, marginLeft: 5 }} />
                                            </Link>
                                        </span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='col-md-4'>
                    <GetSymptomsData reportId={reportId} />
                </div>
            </div>
            <div className="text-right add_top_30 symptomsBtn">
                <input
                    type="submit"
                    onClick={addSymptoms}
                    className="btn_1 patientinfo"
                    value="Save"
                />
                <input
                    type="submit"
                    onClick={onChange}
                    className="btn_1 medicinebtn"
                    value="Next"
                />
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
        </div>
    )
}