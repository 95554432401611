import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { MainNav } from '../../../mainComponent/mainNav';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Button, Modal } from 'react-bootstrap';
import UserLinks from '../partial/uselinks';
import AddLabWorkTable from '../partial/AddLabWorkTable';
import GetLabWork from '../partial/GetLabWork';
import AddWork from './modalBox/addworkmodal';
import AddLab from './modalBox/addLabModal';
import LabWorkList from './LabWorkList';

export default function AddLabWork() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [labWorkModal, setLabWorkModal] = useState(false)
    const [labModal, setLabModal] = useState(false)
    const [workModal, setWorkModal] = useState(false)

    const handleLabworkShow = () => setLabWorkModal(true);
    const handleLabworkClose = () => setLabWorkModal(false);

    const handleLabClose = () => setLabModal(false);
    const handleLabShow = () => setLabModal(true);

    const handleWorkClose = () => setWorkModal(false);
    const handleWorkShow = () => setWorkModal(true);

    const onFormSubmit = () => {
        handleLabClose();
        handleWorkClose();
        handleLabworkClose();
    };

    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50">
                        <Link
                            to={`/doctors/profile/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"> </i>
                        </Link>
                        <span className='float-none ml-2' style={{ fontSize: 'inherit' }}>Lab Work</span>
                    </div>
                    <div className="width50 row justifyContent">
                        <Button
                            type="submit"
                            onClick={() => handleLabShow()}
                            variant="default"
                            className='appColor  mr-3 btn_sub'>
                            Add Lab
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => handleWorkShow()}
                            variant="default"
                            className='appColor btn_sub'>
                            Add Work
                        </Button>
                    </div>
                </div>
            </MainNav>

            <div className='row'>
                <div className='width16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width84 '>
                    <div className="common_box">
                        <div align='right'>
                            <Button className="btn_1 justifyContent" type="submit" onClick={() => handleLabworkShow()}>Add Lab Work</Button>
                        </div>
                        <LabWorkList doctorId={doctorId} />
                        <GetLabWork doctorId={doctorId} />
                    </div>
                </div>
            </div>

            <Modal show={labWorkModal} onHide={handleLabworkClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Lab Work</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddLabWorkTable doctorId={doctorId} onClick={onFormSubmit} />
                </Modal.Body>
            </Modal>

            <Modal show={labModal} onHide={handleLabClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Lab</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddLab onClick={onFormSubmit} />
                </Modal.Body>
            </Modal>

            <Modal show={workModal} onHide={handleWorkClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Work</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddWork onClick={onFormSubmit} />
                </Modal.Body>
            </Modal>
        </Wrapper>
    )
}
