
import React, { useEffect, useState } from "react";
import LabWorkApi from "../../../services/LabWorkApi";
import moment from 'moment';

export default function LabWorkView(props) {
    const { labWorkId } = props
    const { getLabWorkById } = LabWorkApi()
    const [data, setData] = useState([])
    const [toothNumbers, setToothNumbers] = useState([])

    useEffect(() => {
        getLabWorkData();
    }, [])

    const getLabWorkData = () => {
        getLabWorkById(labWorkId)
            .then((res) => {
                setData(res)
                setToothNumbers(res.toothnumber)
            })
    }
    return (
        <div className="patientDataStyle">
            <div >
                <label className="mx-2"><b>Work Name :</b></label>
                {data.workname}
            </div>
            <div >
                <label className="mx-2"><b>Work Type :</b></label>
                {data.worktype}
            </div>
            <div >
                <label className="mx-2"><b>Lab Name :</b></label>
                {data.labname}
            </div>
            <div >
                <label className="mx-2"><b>Lab Contact:</b></label>
                {data.labcontact}
            </div>
            <div >
                <label className="mx-2"><b>Patient Name :</b></label>
                {data.patient}
            </div>
            <div>
                <label className="mx-2"><b>Clinic Name :</b></label>
                {data.clinic}
            </div>
            <div >
                <label className="mx-2"><b>Tooth Number :</b></label>
                {toothNumbers.map((tooth, i) => {
                    return (
                        <span key={i}>
                            {tooth.number},
                        </span>
                    )
                })}
            </div>
            <div >
                <label className="mx-2"><b>Givendate :</b></label>
                {moment(data.givendate).format("DD-MM-YYYY")}
            </div>
            <div >
                <label className="mx-2"><b>Recievedate :</b></label>
                {moment(data.recievedate).format("DD-MM-YYYY")}
            </div>
            <div >
                <label className="mx-2"><b>Price :</b></label>
                {data.price}
            </div>
            <div >
                <label className="mx-2"><b>Comment :</b></label>
                {data.comments}
            </div>
        </div>
    )
}