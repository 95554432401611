import React, { useEffect, useState } from 'react';
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Link, useParams } from 'react-router-dom';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../partial/uselinks';
import { Button } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import moment from 'moment';
import ServicesApi from '../../../services/ServiceApi';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

// const exportToCSV = (csvData) => {
//     // Extracting the column headers from the first object
//     const columns = Object.keys(csvData[0]);
//     // Generating the CSV header
//     let csvContent = columns.join(",") + "\n";
//     // Generating CSV rows
//     csvData.forEach(item => {
//         const row = columns.map(column => {
//             const value = item[column].toString().replace(/"/g, '""');
//             return `"${value}"`;
//         }).join(",");
//         csvContent += row + "\n";
//     });

//     // Creating the CSV file
//     const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
//     const link = document.createElement("a");
//     link.setAttribute("href", encodedUri);
//     link.setAttribute("download", "data.csv");
//     document.body.appendChild(link);
//     link.click();
// }

export default function PaymentList() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [appointmentList, setAppointmentList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { getPaymentDetails } = ServicesApi();
    const pageSize = 5;

    useEffect(() => {
        getPatientDetails(currentPage);
    }, [])

    const handleExport = () => {
        // exportToCSV(appointmentList);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected + 1)
    }

    function getPatientDetails(currentPage) {
        getPaymentDetails(doctorId, currentPage, pageSize)
            .then((result) => {
                setTotalPages(result.totalPages)
                setAppointmentList(result.data)
            })
    }

    return (
        <>
            <Wrapper>
                <MainNav>
                    <div className="clearfix row">
                        <div className="width50">
                            <Link
                                to={`/doctors/profile/${doctorId}`}>
                                <i className="arrow_back backArrow" title="back button"> </i>
                            </Link>
                            <span className='float-none ml-2' style={{ fontSize: 'inherit' }}>Payment Report</span>
                        </div>
                    </div>
                </MainNav>
                <div className='row'>
                    <div className='width16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>
                    <div className='width84'>
                        <div align='right' className=' justify-end'>
                            <label className='mt-2  mr-2' value="month"><b>Download</b></label>
                            <div className='margine-tb'>
                                <Button
                                    type="submit"
                                    onClick={handleExport}
                                    variant="default"
                                    className='lightbuttonColor mb-2 mr-3 btn_sub'>
                                    <i className="icon-download-1" style={{ fontSize: 15 }} />
                                </Button>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"><b>Patient Name</b></TableCell>
                                        <TableCell align="center"><b>Mode of Payment </b></TableCell>
                                        <TableCell align="center"><b>Amount</b></TableCell>
                                        <TableCell align="center"><b>Payment Date</b></TableCell>
                                        <TableCell align="center"><b>Status</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                {appointmentList ?
                                    <TableBody>
                                        {appointmentList && appointmentList.map((item, index) => {
                                            return (
                                                <>
                                                    {item["paymentList"][0] ?
                                                        <TableRow key={index}>
                                                            <TableCell align="center">
                                                                {item["patientDetails"][0].name}
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                {item["paymentList"][0].paymentMethod}
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                {item["paymentList"][0].amount}
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                {moment(item["paymentList"][0].created_at).format("DD-MM-YYYY")}
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                {item["paymentList"][0].status}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                    :
                                    <div align="center"><b>records empty </b></div>
                                }
                            </Table>
                        </TableContainer >
                        {appointmentList ?
                            <div className='mt-2'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination "
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                />
                            </div>
                            : null}
                    </div>
                </div>
            </Wrapper>
        </>
    )
}