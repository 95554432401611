import axios from "axios";
import { API } from "../config";

export default function LabWorkApi() {
    const addLabWork = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/addlabwork`, bodyData);
            return result.data;
        }
        catch (err) {
            return err
        }

    }
    const getlabworkbydoctorId = async (doctorId, currentPage, pageSize, filters) => {
        try {
            const result = await axios.post(`${API}/getlabwork/${doctorId}?page=${currentPage}&pageSize=${pageSize}`,  {filters})
            return result.data
        }
        catch (err) {
            return err
        }
    }

    //lab
    const addLabInfo = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/addnewlab`, bodyData)

            return result.data
        }
        catch (err) {
            return err
        }
    }

    const getlabsInfo = async () => {
        try {
            const result = await axios.get(`${API}/getlabdata`);
            return result.data;
        }
        catch (err) {
            return err
        }

    };

    //work
    const addWorkType = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/addworktype`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }

    };

    const getWorkTypes = async () => {
        try {
            const result = await axios.get(`${API}/getworktype`)
            return result.data
        }
        catch (err) {
            return err
        }

    }

    const getLabWorkById = async (labid) => {
        try {
            const result = await axios.get(`${API}/getlabworkbyid/${labid}`)
            return result.data
        }
        catch (err) {
            return err
        }

    }

    return {
        addLabWork,
        getlabworkbydoctorId,
        addLabInfo,
        getlabsInfo,
        addWorkType,
        getWorkTypes,
        getLabWorkById
    }
}

