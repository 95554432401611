import React, { useEffect, useState } from "react";
import GetSymptomsData from './GetSymptomsData';
import GetLabPrescription from './getLabPrescription';
import GetMedicinePriscription from './GetMedicinePrescription';
import GetDentalServices from "./GetDentalServices";
import ReportApi from "../../../services/ReportApi";
import PatientApi from "../../../services/PatientApi";

export default function PrescriptionSummary(props) {
    const { reportId } = props;
    const { getMedicineReport } = ReportApi();
    const { patientDetailsData } = PatientApi();
    const [viewData, setViewData] = useState([]);
    const [patientDetails, setPatientDetails] = useState([]);

    useEffect(() => {
        getMedicineReportData()
    }, [])

    const getMedicineReportData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setViewData(res[0])
                const patientId = res[0].patientId
                patientDetailsData({ patientId })
                    .then((response) => {
                        setPatientDetails(response[0])
                    })
            })
    }
    return (
        <div className="white-box " >
            <h6 align="left" className='ml-2'><b>Patient Information</b></h6>
            <div className="row viewMreport">
                <div className="col-md-6 mb-2 " align='left' >
                    <div><b>Patient Name :</b>{patientDetails.name}</div>
                    <div><b>Email :</b>{patientDetails.email}</div>
                    <div><b>Gender :</b>{patientDetails.gender}</div>
                    <div><b>Age :</b>{patientDetails.age}</div>
                    {patientDetails.mobile ? <div><b>Mobile no :</b>{patientDetails.mobile}</div> : null}
                </div>
                <div className="col-md-6 " align='left'>
                    <div >

                        {viewData.BMI ?
                            <>
                                <b>BMI :</b>
                                <span>{viewData.BMI}</span>
                            </>
                            :
                            null
                        }
                    </div>

                    <div >
                        {viewData.bp ?
                            <>
                                <b> Bp :</b>
                                <span>{viewData.bp}</span>
                            </>
                            :
                            null
                        }
                    </div>
                    <div >
                        {viewData.height ?
                            <>
                                <b>Height :</b>
                                <span>{viewData.height}</span>
                            </>
                            :
                            null
                        }
                    </div>
                    <div>

                        {viewData.weight ?
                            <>
                                <b>Weight :</b>
                                <span>{viewData.weight}</span>
                            </>
                            :
                            null
                        }
                    </div>
                    <div>

                        {
                            viewData.pulse ?
                                <>
                                    <b>Pulse :</b>
                                    <span>{viewData.pulse}</span>
                                </>
                                :
                                null
                        }
                    </div>
                    <div>

                        {
                            viewData.temp ?
                                <>
                                    <b>Temprature :</b>
                                    <span>{viewData.temp}</span>
                                </>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
            
            <GetMedicinePriscription reportId={reportId} />

            <GetLabPrescription reportId={reportId} />

            <GetSymptomsData reportId={reportId} />

            <GetDentalServices reportId={reportId} />

            {viewData.investigation_note ?
                <div align="left" className='margin_top_15'>
                    <b className='viewMreport'>Investigation :</b>
                    <span>{viewData.investigation_note}</span>
                </div>
                : null
            }

            {viewData.premedication_note ?
                <div align="left " className='viewMreport'>
                    <b >Premedication :</b>
                    <span>{viewData.premedication_note}</span>
                </div>
                : null
            }
        </div>
    )
}