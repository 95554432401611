import { NavLink, useLocation } from "react-router-dom";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { FaClinicMedical, FaNotesMedical } from 'react-icons/fa';
import { useEffect, useState } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export default function UserLinks() {
    const [isReportsActive, setIsReportsActive] = useState(false);
    const location = useLocation()

    // Update the state based on the current URL
    useEffect(() => {
        if (location.pathname.includes(`/subscription`)) {
            setIsReportsActive(true);
        } else {
            setIsReportsActive(false);
        }
    }, [location.pathname]);

    const toggleReportsMenu = () => {
        setIsReportsActive(prevState => !prevState);
    };

    return (
        <div className="sidemenu rightborder" align='left'>
            <NavLink
                className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                to="/doctors">
                <div className="dashboard">
                    <PersonIcon style={{ fontSize: 20 }} />
                    <b className="fontSize ml-1">Doctor Management</b>
                </div>
            </NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                to="/allpatient">
                <div className="dashboard ">
                    <AccessTimeRoundedIcon style={{ fontSize: 20 }} />
                    <b className="fontSize ml-1">Patient Management</b>
                </div>
            </NavLink>
            {/* <NavLink
                className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                to='/subscription'>
                <div className="dashboard">
                    <AttachMoneyRoundedIcon style={{ fontSize: 20 }} />
                    <b className="fontSize ml-1">Subscription</b>
                </div>
            </NavLink> */}
            <NavLink
                className={`${isReportsActive ? "Nav-active" : "none"}`}
                onClick={toggleReportsMenu}
            // to={`/reports/${doctorId}`}
            >
                <div className="dashboard">
                    <AttachMoneyRoundedIcon style={{ fontSize: 20 }} />
                    <b className="fontSize">Subscription</b>
                    {isReportsActive ?
                        <KeyboardArrowDownOutlinedIcon style={{ fontSize: 25, }} />
                        :
                        <ArrowForwardIosIcon style={{ fontSize: 15, }} />
                    }
                </div>
            </NavLink>
            {isReportsActive && (
                <div className="sub-menu">
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                        to={`/subscription/doctorsubscription`}
                    >
                        <div className="dashboard ml-4">
                            {/* <i className="icon_profile ml-4" style={{ fontSize: 20 }} /> */}
                            <b className="fontSize">Doctor Subscription</b>
                        </div>
                    </NavLink>
                    <NavLink
                        className={({ isActive }) => (isActive ? "Nav-active" : 'none')}
                        to={`/subscription/clinicsubscription`}
                    >
                        <div className="dashboard ml-4">
                            {/* <i className="ml-4 icon-doc" style={{ fontSize: 20 }} /> */}
                            {/* <FaClinicMedical style={{ fontSize: 18 }} /> */}
                            <b className="fontSize">Clinic Subscription</b>
                        </div>
                    </NavLink>
                </div>
            )}
            <NavLink
                className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                to='/clinic'>
                <div className="dashboard ">
                    <FaClinicMedical style={{ fontSize: 18 }} />
                    <b className="fontSize ml-1">Clinics</b>
                </div>
            </NavLink>
            {/* <NavLink
                className={({ isActive }) => (isActive ? "Nav-active " : 'none')}
                to='/clinicregistration'>
                <div className="dashboard">
                    <FaNotesMedical style={{ fontSize: 18 }} />
                    <b className="fontSize ml-1">Clinic Registration</b>
                </div>
            </NavLink> */}
        </div>
    )
}  
